import { defineMessages } from 'react-intl'

const messages = defineMessages({
  languageTitle: {
    id: 'Seo.LanguageTitle',
    defaultMessage: 'RA: Resident Advisor - electronic music online',
  },
  languageDescription: {
    id: 'Seo.LanguageDescription',
    defaultMessage: 'RA: Resident Advisor - electronic music online',
  },
  searchTitle: {
    id: 'Seo.SearchTitle',
    defaultMessage: 'RA: Resident Advisor - electronic music online',
  },
  searchDescription: {
    id: 'Seo.SearchDescription',
    defaultMessage: 'RA: Resident Advisor - electronic music online',
  },
  registerTitle: {
    id: 'Seo.RegisterTitle',
    defaultMessage: 'Register ⟋ RA',
  },
  registerDescription: {
    id: 'Seo.RegisterDescription',
    defaultMessage: 'Create an account',
  },
  pitchTitle: {
    id: 'Seo.PitchTitle',
    defaultMessage: 'Pitch ⟋ RA',
  },
  pitchDescription: {
    id: 'Seo.PitchDescription',
    defaultMessage: 'Pitch',
  },
  forgotPasswordTitle: {
    id: 'Seo.ForgotPasswordTitle',
    defaultMessage: 'Forgot Password ⟋ RA',
  },
  forgotPasswordDescription: {
    id: 'Seo.ForgotPasswordDescription',
    defaultMessage: 'Reset your password',
  },
  loginTitle: {
    id: 'Seo.LoginTitle',
    defaultMessage: 'Login ⟋ RA',
  },
  loginDescription: {
    id: 'Seo.LoginDescription',
    defaultMessage: 'Login',
  },
  resetPasswordTitle: {
    id: 'Seo.ResetPasswordTitle',
    defaultMessage: 'Reset Password ⟋ RA',
  },
  verifyEmailTitle: {
    id: 'Seo.VerifyEmailTitle',
    defaultMessage: 'Verify Email ⟋ RA',
  },
  resendVerificationEmail: {
    id: 'Seo.ResendVerificationEmail',
    defaultMessage: 'Resend Verification Email ⟋ RA',
  },
  contributorDetailTitle: {
    id: 'Seo.ContributorDetailTitle',
    defaultMessage: '{name} · Profile ⟋ RA',
  },
  contributorDetailDescription: {
    id: 'Seo.ContributorDetailDescription',
    defaultMessage: 'RA: Resident Advisor - electronic music online',
  },
  exchangeTitle: {
    id: 'Seo.ExchangeTitle',
    defaultMessage: 'RA Exchange',
  },
  exchangeDescription: {
    id: 'Seo.ExchangeDescription',
    defaultMessage:
      'The weekly RA Exchange is a series of conversations with artists, labels and promoters shaping the electronic music landscape.',
  },
  featureDetailRAFilmsTitle: {
    id: 'Seo.FeatureDetailRAFilmsTitle',
    defaultMessage: '{title} ⟋ RA Films',
  },
  featureDetailRAFeatureTitle: {
    id: 'Seo.FeatureDetailRAFeatureTitle',
    defaultMessage: '{title} · Feature ⟋ RA',
  },
  featureDetailRADescription: {
    id: 'Seo.FeatureDetailRADescription',
    defaultMessage: '{description}',
  },
  featureFilmSeriesTitle: {
    id: 'Seo.FeatureFilmSeriesTitle',
    defaultMessage: '{title}  · Film Series ⟋ RA Films',
  },
  featureFeatureSeriesTitle: {
    id: 'Seo.FeatureSeriesTitle',
    defaultMessage: '{title}  · Feature Series ⟋ RA',
  },
  featureFilmsHomeTitleStandard: {
    id: 'Seo.FeatureFilmsHomeTitleStandard',
    defaultMessage: '{pageTitle} ⟋ RA',
  },
  featureFilmsHomeTitle: {
    id: 'Seo.FeatureFilmsHomeTitle',
    defaultMessage: '{pageTitle} ⟋ RA Films',
  },
  labelDetailTitle: {
    id: 'Seo.LabelDetailTitle',
    defaultMessage: '{name} · Record label ⟋ RA',
  },
  magazineHomeTitle: {
    id: 'Seo.MagazineHomeTitle',
    defaultMessage: 'News, Features, Exchanges and Videos · Magazine ⟋ RA',
  },
  magazineHomeDescription: {
    id: 'Seo.MagazineHomeDescription',
    defaultMessage: 'Interviews, films and news from across the scene.',
  },
  mixOfTheDayTitle: {
    id: 'Seo.MixOfTheDayTitle',
    defaultMessage: 'Mix of the day ⟋ RA',
  },
  mixOfTheDayDescription: {
    id: 'Seo.MixOfTheDayDescription',
    defaultMessage: 'Daily mixes from the world of electronic music.',
  },
  musicHomeTitle: {
    id: 'Seo.MusicHomeTitle',
    defaultMessage: 'Reviews, Podcasts and Playlists ⟋ RA',
  },
  musicHomeDescription: {
    id: 'Seo.MusicHomeDescription',
    defaultMessage: 'Everything you need to hear in electronic music.',
  },
  newsSeoUtilsTitle: {
    id: 'Seo.NewsSeoUtilsTitle',
    defaultMessage: '{title} · News ⟋ RA',
  },
  newsHomeTitle: {
    id: 'Seo.NewsHomeTitle',
    defaultMessage: 'The latest in electronic dance music · News ⟋ RA',
  },
  playlistsHomeTitle: {
    id: 'Seo.PlaylistsHomeTitle',
    defaultMessage: 'Playlists ⟋ RA',
  },
  playlistsHomeDescription: {
    id: 'Seo.PlaylistsHomeDescription',
    defaultMessage:
      "RA's playlists showcase the best music from the past, present and future of electronic music.",
  },
  podcastHomeTitle: {
    id: 'Seo.PodcastHomeTitle',
    defaultMessage: 'RA Podcast',
  },
  podcastHomeDescription: {
    id: 'Seo.PodcastHomeDescription',
    defaultMessage:
      'The weekly RA Podcast features an exclusive mix of electronic music from top producers and DJs around the world.',
  },
  podcastDetailTitle: {
    id: 'Seo.PodcastDetailTitle',
    defaultMessage: '{title} ⟋ RA Podcast',
  },
  exchangeDetailTitle: {
    id: 'Seo.ExchangeDetailTitle',
    defaultMessage: '{title} ⟋ RA Exchange',
  },
  reviewArticleTitle: {
    id: 'Seo.ReviewArticleTitle',
    defaultMessage: '{title} · {type} Review ⟋ RA',
  },
  reviewPageTemplateTitle: {
    id: 'Seo.ReviewPageTemplateTitle',
    defaultMessage: '{title} · Reviews ⟋ RA',
  },
  reviewPageTemplateDescription: {
    id: 'Seo.ReviewPageTemplateDescription',
    defaultMessage: 'RA: Resident Advisor',
  },
  artistProfileSeoTitle: {
    id: 'Seo.ArtistProfileSeoTitle',
    defaultMessage: '{name} · Artist Profile',
  },
  tourDatesSeoTitle: {
    id: 'Seo.TourDatesSeoTitle',
    defaultMessage: '{name} · Tour Dates & Tickets',
  },
  pastEventsSeoTitle: {
    id: 'Seo.PastEventsSeoTitle',
    defaultMessage: '{name} · Past Events',
  },
  biographySeoTitle: {
    id: 'Seo.BiographySeoTitle',
    defaultMessage: '{name} · Biography',
  },
  latestNewsSeoTitle: {
    id: 'Seo.LatestNewsSeoTitle',
    defaultMessage: '{name} · Latest News',
  },
  reviewsAndFeaturesSeoTitle: {
    id: 'Seo.ReviewsAndFeaturesSeoTitle',
    defaultMessage: '{name} · Reviews & Features',
  },
  defaultArtistSeoTitle: {
    id: 'Seo.DefaultArtistSeoTitle',
    defaultMessage: '{name} · Artist ⟋ RA',
  },
  artistPageTypeAbout: {
    id: 'Seo.ArtistPageTypeAbout',
    defaultMessage: 'about',
  },
  artistPageTypeUpcomingEvents: {
    id: 'Seo.ArtistPageTypeUpcomingEvents',
    defaultMessage: 'upcomingEvents',
  },
  artistPageTypePastEvents: {
    id: 'Seo.ArtistPageTypePastEvents',
    defaultMessage: 'pastEvents',
  },
  artistPageTypeBiography: {
    id: 'Seo.ArtistPageTypeBiography',
    defaultMessage: 'biography',
  },
  artistPageTypeNews: {
    id: 'Seo.ArtistPageTypeNews',
    defaultMessage: 'news',
  },
  artistPageTypeEditorial: {
    id: 'Seo.ArtistPageTypeEditorial',
    defaultMessage: 'editorial',
  },
  aboutTitle: {
    id: 'Seo.AboutTitle',
    defaultMessage: '{name} · Artist Profile',
  },
  upcomingEventsTitle: {
    id: 'Seo.UpcomingEventsTitle',
    defaultMessage: '{name} · Tour Dates & Tickets',
  },
  pastEventsTitle: {
    id: 'Seo.PastEventsTitle',
    defaultMessage: '{name} · Past Events',
  },
  biographyTitle: {
    id: 'Seo.BiographyTitle',
    defaultMessage: '{name} · Biography',
  },
  newsTitle: {
    id: 'Seo.NewsTitle',
    defaultMessage: '{name} · Latest News',
  },
  editorialTitle: {
    id: 'Seo.EditorialTitle',
    defaultMessage: '{name} · Reviews & Features',
  },
  defaultTitle: {
    id: 'Seo.DefaultTitle',
    defaultMessage: '{name} · Artist ⟋ RA',
  },
  aboutTabTitle: {
    id: 'Seo.AboutTabTitle',
    defaultMessage: 'About',
  },
  upcomingEventsTabTitle: {
    id: 'Seo.UpcomingEventsTabTitle',
    defaultMessage: 'Tour Dates',
  },
  pastEventsTabTitle: {
    id: 'Seo.PastEventsTabTitle',
    defaultMessage: 'Past Events',
  },
  biographyTabTitle: {
    id: 'Seo.BiographyTabTitle',
    defaultMessage: 'Biography',
  },
  newsTabTitle: {
    id: 'Seo.NewsTabTitle',
    defaultMessage: 'News',
  },
  editorialTabTitle: {
    id: 'Seo.EditorialTabTitle',
    defaultMessage: 'Reviews & Features',
  },
  defaultTabTitle: {
    id: 'Seo.DefaultTabTitle',
    defaultMessage: '{name} · {tabTitle} · Artist ⟋ RA',
  },
  defaultTabTitleAlt: {
    id: 'Seo.DefaultTabTitleAlt',
    defaultMessage: '{name} · Artist ⟋ RA',
  },
  aboutPageType: {
    id: 'Seo.AboutPageType',
    defaultMessage: 'RA: Resident Advisor',
  },
  upcomingEventsPageType: {
    id: 'Seo.UpcomingEventsPageType',
    defaultMessage: "Discover {name}'s upcoming events on RA.{blurb}",
  },
  pastEventsPageType: {
    id: 'Seo.PastEventsPageType',
    defaultMessage: '{name} event archive.{blurb}',
  },
  newsPageType: {
    id: 'Seo.NewsPageType',
    defaultMessage: '{name} latest news.{blurb}',
  },
  editorialPageType: {
    id: 'Seo.EditorialPageType',
    defaultMessage: '{name} reviews and features.{blurb}',
  },
  defaultPageType: {
    id: 'Seo.DefaultPageType',
    defaultMessage: 'RA: Resident Advisor',
  },
  upcomingEventsSharingDescription: {
    id: 'Seo.UpcomingEventsSharingDescription',
    defaultMessage:
      '{name} tour dates and shows for {formattedYears}. Find upcoming events and buy {name} tickets.',
  },
  pastEventsSharingDescription: {
    id: 'Seo.PastEventsSharingDescription',
    defaultMessage: '{name} tour dates archive, past events featuring {name}.',
  },
  defaultSharingDescription: {
    id: 'Seo.DefaultSharingDescription',
    defaultMessage: 'RA: Resident Advisor',
  },
  clubPageTypeOverviewRegionTitle: {
    id: 'Seo.ClubPageTypeOverviewRegionTitle',
    defaultMessage: '{name} · Upcoming Events & Tickets',
  },
  clubPageTypeOverviewTitle: {
    id: 'Seo.ClubPageTypeOverviewTitle',
    defaultMessage: '{name}, {region} · Upcoming Events & Tickets',
  },
  clubPageTypeUpcomingEventsRegionTitle: {
    id: 'Seo.ClubPageTypeUpcomingEventsRegionTitle',
    defaultMessage: "What's on at {name} · Get Your Tickets On RA",
  },
  clubPageTypeUpcomingEventsTitle: {
    id: 'Seo.ClubPageTypeUpcomingEventsTitle',
    defaultMessage: "What's on at {name}, {region} · Get Your Tickets On RA",
  },
  clubPageTypePastEventsRegionTitle: {
    id: 'Seo.ClubPageTypePastEventsRegionTitle',
    defaultMessage: '{name} · Past Events',
  },
  clubPageTypePastEventsTitle: {
    id: 'Seo.ClubPageTypePastEventsTitle',
    defaultMessage: '{name}, {region} · Past Events',
  },
  clubPageTypeNewsRegionTitle: {
    id: 'Seo.ClubPageTypeNewsRegionTitle',
    defaultMessage: '{name} · Latest News',
  },
  clubPageTypeNewsTitle: {
    id: 'Seo.ClubPageTypeNewsTitle',
    defaultMessage: '{name}, {region} · Latest News',
  },
  clubTabTitleUpcomingEvents: {
    id: 'Seo.ClubTabTitleUpcomingEvents',
    defaultMessage: 'Upcoming Events',
  },
  clubTabTitlePastEvents: {
    id: 'Seo.ClubTabTitlePastEvents',
    defaultMessage: 'Past Events',
  },
  clubTabTitleNews: {
    id: 'Seo.ClubTabTitleNews',
    defaultMessage: 'RA News',
  },
  clubSharingTitleDefault: {
    id: 'Seo.ClubSharingTitleDefault',
    defaultMessage: '{name} · {tabTitle} · Club ⟋ RA',
  },
  clubSharingTitleAlt: {
    id: 'Seo.ClubSharingTitleAlt',
    defaultMessage: '{name} · Club ⟋ RA',
  },
  clubPageOverviewDescription: {
    id: 'Seo.ClubPageOverviewDescription',
    defaultMessage:
      'Discover events and find tickets for {name}, {region} on RA.',
  },
  clubPageOverviewDescriptionWithRegion: {
    id: 'Seo.ClubPageOverviewDescriptionWithRegion',
    defaultMessage: 'Discover events and find tickets for {name} on RA.',
  },
  clubPageUpcomingEventsDescription: {
    id: 'Seo.ClubPageUpcomingEventsDescription',
    defaultMessage:
      'Find upcoming events & tickets for {name}, {region} on RA.',
  },
  clubPageUpcomingEventsDescriptionWithRegion: {
    id: 'Seo.ClubPageUpcomingEventsDescriptionWithRegion',
    defaultMessage: 'Find upcoming events & tickets for {name} on RA.',
  },
  clubPagePastEvents: {
    id: 'Seo.ClubPagePastEvents',
    defaultMessage: '{name} event archive.',
  },
  clubPageNews: {
    id: 'Seo.ClubPageNews',
    defaultMessage:
      'Find the latest news & line-up announcements for {name} on RA.',
  },
  festivalsHomeCountryTitle: {
    id: 'Seo.FestivalsHomeCountryTitle',
    defaultMessage: 'Electronic Music Festivals · {country}',
  },
  festivalsHomeTitle: {
    id: 'Seo.FestivalsHomeTitle',
    defaultMessage: 'Electronic Music Festivals',
  },
  festivalsHomeCountryDescription: {
    id: 'Seo.FestivalsHomeCountryDescription',
    defaultMessage:
      'Upcoming music festivals in {country}. Find the latest news, line-up announcements & tickets on RA.',
  },
  festivalsHomeDescription: {
    id: 'Seo.FestivalsHomeDescription',
    defaultMessage:
      'Upcoming music festivals. Find the latest news, line-up announcements & tickets on RA.',
  },
  promoterTitleOverview: {
    id: 'Seo.PromoterTitleOverview',
    defaultMessage: '{name} · Upcoming Events, Tickets & News',
  },
  promoterTitleUpcomingEvents: {
    id: 'Seo.PromoterTitleUpcomingEvents',
    defaultMessage: '{name} · Event Calendar',
  },
  promoterTitlePastEvents: {
    id: 'Seo.PromoterTitlePastEvents',
    defaultMessage: '{name} · Past Events',
  },
  promoterTitleNews: {
    id: 'Seo.PromoterTitleNews',
    defaultMessage: '{name} · Latest News',
  },
  promoterTabTitleOverview: {
    id: 'Seo.PromoterTabTitleOverview',
    defaultMessage: 'Overview',
  },
  promoterTabTitleUpcomingEvents: {
    id: 'Seo.PromoterTabTitleUpcomingEvents',
    defaultMessage: 'Upcoming Events',
  },
  promoterTabTitlePastEvents: {
    id: 'Seo.PromoterTabTitlePastEvents',
    defaultMessage: 'Past Events',
  },
  promoterTabTitleNews: {
    id: 'Seo.PromoterTabTitleNews',
    defaultMessage: 'RA News',
  },
  promoterSharingTitleDefault: {
    id: 'Seo.PromoterSharingTitleDefault',
    defaultMessage: '{name} · {tabTitle} · Promoter ⟋ RA',
  },
  promoterSharingTitleAlt: {
    id: 'Seo.PromoterSharingTitleDefault',
    defaultMessage: '{name} · Promoter ⟋ RA',
  },
  promoterDescriptionOverview: {
    id: 'Seo.PromoterDescriptionOverview',
    defaultMessage:
      'Discover events and find tickets for {name} on RA. {blurb}',
  },
  promoterDescriptionUpcomingEvents: {
    id: 'Seo.PromoterDescriptionUpcomingEvents',
    defaultMessage: '{name} upcoming events and tickets. {blurb}',
  },
  promoterDescriptionPastEvents: {
    id: 'Seo.PromoterDescriptionPastEvents',
    defaultMessage: '{name} event archive. {blurb}',
  },
  promoterDescriptionNews: {
    id: 'Seo.PromoterDescriptionNews',
    defaultMessage:
      'Find the latest news & line-up announcements for {name} on RA. {blurb}',
  },
  promoterSharingDescriptionUpcomingEvents: {
    id: 'Seo.PromoterSharingDescriptionUpcomingEvents',
    defaultMessage:
      '{name} events and shows for {currentYear} & {followingYear}. Find upcoming events and buy {name} tickets.',
  },
  promoterSharingDescriptionPastEvents: {
    id: 'Seo.PromoterSharingDescriptionPastEvents',
    defaultMessage: '{name} events archive, past events by {name}.',
  },
  homeTitle: {
    id: 'Seo.HomeTitle',
    defaultMessage: 'RA · Discover Electronic Music and Events',
  },
  homeDescription: {
    id: 'Seo.HomeDescription',
    defaultMessage:
      'Resident Advisor is your global guide to clubs, DJs, tickets, news and rave culture. See you front left.',
  },
  eventRevisionTitle: {
    id: 'Seo.EventRevisionTitle',
    defaultMessage: 'Event Revisions ⟋ RA',
  },
  eventRevisionDescription: {
    id: 'Seo.EventRevisionDescription',
    defaultMessage: 'View revisions for event',
  },
  proHomeTitle: {
    id: 'Seo.ProHomeTitle',
    defaultMessage: 'RA Pro',
  },
  proHomeDescription: {
    id: 'Seo.ProHomeDescription',
    defaultMessage: 'RA Pro',
  },
  promoterPaymentDetailsTitle: {
    id: 'Seo.PromoterPaymentDetailsTitle',
    defaultMessage: 'Payment Details / RA',
  },
  promoterPaymentDetailsDescription: {
    id: 'Seo.PromoterPaymentDetailsDescription',
    defaultMessage: 'RA Payment Details',
  },
  promotersHomeTitle: {
    id: 'Seo.PromotersHomeTitle',
    defaultMessage: 'My Promoters / RA Pro',
  },
  promotersHomeDescription: {
    id: 'Seo.PromotersHomeDescription',
    defaultMessage: 'Manage your promoters',
  },
  sourceSavedArtistsPageTitle: {
    id: 'Seo.SourceSavedArtistsPageTitle',
    defaultMessage: 'SOURCE Saved Artists ⟋ RA Pro',
  },
  sourceSavedArtistsPageDescription: {
    id: 'Seo.SourceSavedArtistsPageDescription',
    defaultMessage: 'SOURCE Saved Artists',
  },
  sourceSavedArtistListPageTitle: {
    id: 'Seo.SourceSavedArtistListPageTitle',
    defaultMessage: 'SOURCE Saved Artist List ⟋ RA Pro',
  },
  sourceSavedArtistListPageDescription: {
    id: 'Seo.SourceSavedArtistListPageDescription',
    defaultMessage: 'SOURCE Saved Artist List',
  },
  locationPageTitle: {
    id: 'Seo.LocationPageTitle',
    defaultMessage: 'RA: Resident Advisor - electronic music online',
  },
  locationPageDescription: {
    id: 'Seo.LocationPageDescription',
    defaultMessage: 'RA: Resident Advisor - electronic music online',
  },
  userSettingsPageTitle: {
    id: 'Seo.UserSettingsPageTitle',
    defaultMessage: '{tabTitle} · {tabTitleSecondary} ⟋ RA',
  },
  userSettingsPageDescription: {
    id: 'Seo.UserSettingsPageDescription',
    defaultMessage: 'RA: Resident Advisor',
  },
  userSettingsPageTabTitleSecondary: {
    id: 'Seo.UserSettingsTabTitleSecondary',
    defaultMessage: 'Settings',
  },
  userPageTemplateTitle: {
    id: 'Seo.UserPageTemplateTitle',
    defaultMessage: '{tabTitle} · {tabTitleSecondary} ⟋ RA',
  },
  userPageTemplateDescription: {
    id: 'Seo.UserPageTemplateDescription',
    defaultMessage: 'RA: Resident Advisor',
  },
  followingTabTitle: {
    id: 'Seo.FollowingTabTitle',
    defaultMessage: 'Following',
  },
  followingTabTitleSecondary: {
    id: 'Seo.FollowingTabTitleSecondary',
    defaultMessage: 'User',
  },
  myTicketsTabTitle: {
    id: 'Seo.MyTicketsTabTitle',
    defaultMessage: 'My Tickets',
  },
  micrositeDetailTitle: {
    id: 'Seo.MicrositeDetailTitle',
    defaultMessage: '{title} ⟋ RA',
  },
  eventListingsForYouTitle: {
    id: 'Seo.EventListingsForYouTitle',
    defaultMessage:
      'Upcoming Events for you in {region} · Get Your Tickets On RA',
  },
  eventListingsJustAnnouncedTitle: {
    id: 'Seo.EventListingsJustAnnouncedTitle',
    defaultMessage:
      'Upcoming Events just announced in {region} · Get Your Tickets On RA',
  },
  eventListingsRAPicksTitle: {
    id: 'Seo.EventListingsRAPicksTitle',
    defaultMessage:
      'Upcoming RA Pick Events in {region} · Get Your Tickets On RA',
  },
  eventListingsAllTitle: {
    id: 'Seo.EventListingsAllTitle',
    defaultMessage: 'Upcoming Events in {region} · Get Your Tickets On RA',
  },
  eventListingsAllDescription: {
    id: 'Seo.EventListingsAllDescription',
    defaultMessage:
      'Discover upcoming electronic music events in {region} and get your tickets on RA.',
  },
  eventListingsAllTitleGenre: {
    id: 'Seo.EventListingsAllTitleGenre',
    defaultMessage:
      'Upcoming {genreName} Events in {region} · Get Your Tickets On RA',
  },
  eventListingsAllTitleThisWeekend: {
    id: 'Seo.EventListingsAllTitleThisWeekend',
    defaultMessage: 'Events in {region} this weekend · Get Your Tickets On RA',
  },
  eventListingsAllTitleGenreThisWeekend: {
    id: 'Seo.EventListingsTitleGenreThisWeekend',
    defaultMessage:
      '{genreName} Events in {region} this weekend · Get Your Tickets On RA',
  },
  eventListingsAllDescriptionThisWeekend: {
    id: 'Seo.EventListingsDescriptionThisWeekend',
    defaultMessage:
      'Discover electronic music events in {region} this weekend and get your tickets on RA.',
  },
  guidePageTitle: {
    id: 'Seo.GuidePageTitle',
    defaultMessage: 'RA Guide to {region}',
  },
  ticketedEventTitle: {
    id: 'Seo.TicketedEventTitle',
    defaultMessage: '{title} at {name}, {safeAreaName} · Tickets',
  },
  nonTicketedEventTitle: {
    id: 'Seo.NonTicktedEventTitle',
    defaultMessage: '{title} at {name}, {safeAreaName}',
  },
  ticketedEventTitleForSharing: {
    id: 'Seo.TicketedEventTitleForSharing',
    defaultMessage: '{title} at {name}, {safeAreaName} ⟋ RA Tickets',
  },
  nonTicketedEventDateTitleForSharing: {
    id: 'Seo.NonTicketedEventDateTitleForSharing',
    defaultMessage: '{title} at {name}, {safeAreaName} ({year}) ⟋ RA',
  },
  defaultEventTitleForSharing: {
    id: 'Seo.DefaultEventTitleForSharing',
    defaultMessage: '{title} at {name}, {safeAreaName} ⟋ RA',
  },
  lineupAndMore: {
    id: 'Seo.LineupAndMore',
    defaultMessage: '& More',
  },
  lineupTBA: {
    id: 'Seo.LineupTBA',
    defaultMessage: 'TBA',
  },
  eventDescriptionLineup: {
    id: 'Seo.EventDescriptionLineup',
    defaultMessage: '{eventDate}. Line-Up: {lineup}.',
  },
  ticketedEventDescription: {
    id: 'Seo.TicketedEventDescription',
    defaultMessage: '{result} Get Your Tickets On RA.',
  },
  savedUpcomingEventsTitle: {
    id: 'Seo.SavedUpcomingEventsTitle',
    defaultMessage: 'Saved events · Upcoming ⟋ RA',
  },
  savedPastEventsTitle: {
    id: 'Seo.SavedPastEventsTitle',
    defaultMessage: 'Saved events · Past  ⟋ RA',
  },
})

export default messages
